<template>
    <v-btn depressed rounded :text="!boton" :block="block" :color="color" @click="logout()">
        Cerrar sesión
    </v-btn>
</template>

<script>
import axios from "axios";
export default {
    props: ['boton', 'block', "color"],
    methods: {
        async logout() {
            const response = await this.$store.dispatch('logout')

            if (response.success) {
                console.log("logout exitoso");
                this.drawer = false
                this.$router.push({ name: "welcome" });
            } else {
                console.log("Error en el logout");
            }
        },
    }
}
</script>

<style>

</style>