<template>
    <!-- App.vue -->
    <v-app>
        <v-navigation-drawer app color="rgba(23, 26, 75, .75)" v-model="drawer" v-if="$store.state.isAuthenticated">
            <template v-slot:prepend>
                <v-list-item two-line>
                    <v-list-item-avatar color="secondary" size="48" class="justify-center text-uppercase">
                        <!-- <img src="https://randomuser.me/api/portraits/women/81.jpg"> -->
                        <span class="white--text text-h6">{{ $store.state.iniciales }}</span>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ $store.state.user.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $store.state.user.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <!-- <div class="pa-2">
                <v-img src="@/assets/en-vivo-8.png" height="50" contain @click="irEnVivo()"></v-img>
            </div> -->

            <v-list dense nav>
                <v-list-item link :to="{ name: 'categoria', params: { nombre: 'IA en Salud' } }">
                    <v-list-item-icon class="btn-degradado rounded-circle">
                        <v-icon>mdi mdi-microphone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>IA en Salud</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'categoria', params: { nombre: 'Salud Legal' } }">
                    <v-list-item-icon class="btn-degradado rounded-circle">
                        <v-icon>mdi mdi-microphone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Salud Legal</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'categoria', params: { nombre: 'Ginecoinfluencer' } }">
                    <v-list-item-icon class="btn-degradado rounded-circle">
                        <v-icon>mdi mdi-microphone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Ginecoinfluencer</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <template v-slot:append>
                <div class="pa-2">
                    <logout :boton="true" color="secondary" :block="true"></logout>
                </div>
            </template>
        </v-navigation-drawer>

        <v-app-bar app elevate-on-scroll :color="bg" v-if="$store.state.isAuthenticated">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-img src="@/assets/Logo_Ginexepodcast.webp" height="100%" width="1" contain></v-img>
            <v-menu bottom min-width="200px" rounded offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on">
                        <v-avatar color="primary" size="48">
                            <span class="white--text text-h6">{{ $store.state.iniciales }}</span>
                        </v-avatar>
                    </v-btn>
                </template>
                <v-card>
                    <v-list-item-content class="justify-center">
                        <div class="mx-auto text-center">
                            <v-avatar color="primary">
                                <span class="white--text text-h6">{{ $store.state.iniciales }}</span>
                            </v-avatar>
                            <h3>{{ $store.state.user.name }}</h3>
                            <p class="text-caption mt-1">
                                {{ $store.state.user.email }}
                            </p>
                            <v-divider class="my-3"></v-divider>
                            <v-btn depressed rounded text :to="{ name: 'perfil' }">
                                Perfil
                            </v-btn>
                            <v-divider class="my-3"></v-divider>
                            <logout></logout>
                        </div>
                    </v-list-item-content>
                </v-card>
            </v-menu>
        </v-app-bar>

        <!-- Sizes your content based upon application components -->
        <v-main>
            <!-- Provides the application the proper gutter -->
            <v-container fluid fill-height>
                <escuchando v-if="$route.name != 'audio'"></escuchando>
                <!-- If using vue-router -->
                <router-view :key="$route.fullPath"></router-view>
            </v-container>
        </v-main>

        <v-footer app padless class="justify-space-between py-3" color="primary" v-if="$store.state.isAuthenticated">
            <v-btn icon active-class="btn-degradado" class="mx-4" :to="{ name: 'home' }">
                <v-icon size="25">mdi-home</v-icon>
            </v-btn>
            <v-btn icon active-class="btn-degradado" class="mx-4" :to="{ name: 'mas-vistos' }">
                <v-icon size="25">mdi-heart</v-icon>
            </v-btn>

            <v-btn icon active-class="btn-degradado" class="mx-4" :to="{ name: 'descargas' }"
                :class="{'animate__animated animate__heartBeat animate__infinite	infinite': $store.state.animarDescargas}"
                :color="$store.state.animarDescargas ? 'secondary' : 'white'">
                <v-icon size="25">mdi-download</v-icon>
            </v-btn>

            <v-btn icon active-class="btn-degradado" class="mx-4" :to="{ name: 'buscar' }">
                <v-icon size="25">mdi-magnify</v-icon>
            </v-btn>
            <!-- <v-btn icon active-class="btn-degradado" class="mx-4" :class="{'animate__animated animate__heartBeat animate__infinite infinite': $route.name != 'vivo'}" color="red" :to="{ name: 'vivo' }">
                <v-icon size="40">mdi-access-point</v-icon>
            </v-btn>
            <v-btn icon active-class="btn-degradado" class="mx-4" :to="{ name: 'perfil' }">
                <v-icon size="25">mdi-account</v-icon>
            </v-btn> -->
        </v-footer>
    </v-app>
</template>

<script>
import Escuchando from './components/Escuchando.vue';
import Logout from './components/Logout.vue'
import PWAPrompt from "vue2-ios-pwa-prompt";
export default {
    components: { Logout, Escuchando, PWAPrompt },
    data: () => ({
        drawer: false,
        cargando: true,
        bg: 'transparent',
    }),
    methods: {
        irEnVivo() {
            this.$router.push({ name: 'vivo' })
        },
        changeColor() {
            if (
                document.body.scrollTop > 1 ||
                document.documentElement.scrollTop > 1
            ) {
                this.bg = 'primary';
            } else {
                this.bg = 'transparent';
            }
        },
        async logout() {
            const response = await this.$store.dispatch('logout')

            if (response.success) {
                console.log("logout exitoso");
                this.drawer = false
                this.$router.push({ name: "welcome" });
            } else {
                console.log("Error en el logout");
            }
        },
        async getCategorias() {
            const response = await this.$store.dispatch('getCategorias')

            if (response.success) {
                console.log("categorias exitoso");
            } else {
                console.log("Error categorias");
            }
        }
    },
    async created() {
        const token = localStorage.getItem('token');
        if (token && token != 'undefined') {
            console.log(token);
            this.$store.commit('SET_TOKEN', token);
            
            const response = await this.$store.dispatch('getUser', token)

            if (response.success && this.$route.name != 'home') {
                console.log("token exitoso");
                this.$router.push({ name: 'home' });
                this.getCategorias()
            } else {
                console.log("Error en el token");
            }
        }
    },
    mounted() {
        console.log(this.$vuetify.theme.dark)
        window.onscroll = () => {
            this.changeColor();
        };
    }
}
</script>

<style scoped lang="scss">
#app {
    background-image: url('@/assets/fondo-podcast.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

main {
    background-size: cover;
    background-position: center;

    &.sombra {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: rgb(23, 26, 75);
            background: -moz-linear-gradient(0deg, rgba(23, 26, 75, 1) 0%, rgba(23, 26, 75, 1) 25%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 1) 100%);
            background: -webkit-linear-gradient(0deg, rgba(23, 26, 75, 1) 0%, rgba(23, 26, 75, 1) 25%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 1) 100%);
            background: linear-gradient(0deg, rgba(23, 26, 75, 1) 0%, rgba(23, 26, 75, 1) 25%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171a4b", endColorstr="#000000", GradientType=1);
        }
    }
    &.vivo {
        background-position: left center;
    }
}
</style>