import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";

import "./scss/main.scss";

// import MarqueeText from "vue-marquee-text-component";
// Vue.component("marquee-text", MarqueeText);

import mixin from "./mixins";
Vue.mixin(mixin);

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;
// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.baseURL = "https://ginexeltis.mx";
axios.defaults.withCredentials = true;
axios.get("/sanctum/csrf-cookie").then((response) => {});

import AudioVisual from "vue-audio-visual";
Vue.use(AudioVisual);

import "animate.css";

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
