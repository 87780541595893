<template>
    <v-container class="contenedor rounded-lg mb-auto" v-if="$store.state.audio" @click="irAudio()">
        <v-row no-gutters align="center">
            <v-col cols="2">
                <v-icon x-large class="animate__animated animate__heartBeat animate__infinite infinite">mdi-microphone</v-icon>
            </v-col>
            <v-col cols="10">
                <p class="mb-0 text-caption">Estas escuchando:</p>
                <marquee-text class="font-weight-bold text-subtitle-2 mb-0" id="nombre" :duration="50">
                    {{ $store.state.audio.nombre }}
                </marquee-text>
                <!-- <p class="font-weight-bold text-subtitle-2 mb-0" id="nombre">{{ $store.state.audio.nombre }}</p> -->
            </v-col>
            <v-col cols="auto" class="d-none">
                <audio :src="$store.state.url" preload controls controlsList="nodownload noplaybackrate nofullscreen" ref="audio2" class="d-none">
                    Your browser does not support the <code>audio</code> element.
                </audio>
                <av-line :canv-width="70" :canv-height="40" line-color="#cc5598" :line-width=".25" :audio-src="$store.state.url" ref-link="audio2" @playing="playing" @paused="paused" @ended="ended" v-if="getDeviceType != 'iOS'"></av-line>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'
export default {
    components: { MarqueeText },
    methods: {
        playing() {
            
        },
        paused() {

        },
        ended() {
            this.$store.state.audio = null
        },
        irAudio() {
            this.$router.push({name: 'audio', params: {id: this.$store.state.audio.id}})
        }
    },
    mounted() {
        if (this.$store.state.audio) {
            this.$refs.audio2.currentTime = this.$store.state.audio.currentTime
            this.$refs.audio2.play()
        }
    },
    beforeDestroy() {
        if (this.$store.state.audio) {
            this.$store.state.audio.currentTime = this.$refs.audio2.currentTime
        }
    }
}
</script>

<style scoped>
.contenedor {
    background-color: var(--v-primary-base);
}
#nombre {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
</style>