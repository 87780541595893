import store from "@/store";
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/instalar",
    name: "instalar",
    component: () =>
      import(/* webpackChunkName: "instalar" */ "../views/Instalar.vue"),
  },
  {
    path: "/",
    name: "welcome",
    component: () =>
      import(/* webpackChunkName: "welcome" */ "../views/Welcome.vue"),
  },
  {
    path: "/registro",
    name: "registro",
    component: () =>
      import(/* webpackChunkName: "registro" */ "../views/Registro.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/descargas",
    name: "descargas",
    component: () =>
      import(/* webpackChunkName: "descargas" */ "../views/Descargas.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/vivo",
    name: "vivo",
    component: () => import(/* webpackChunkName: "vivo" */ "../views/Vivo.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/mas-vistos",
    name: "mas-vistos",
    component: () =>
      import(/* webpackChunkName: "mas-vistos" */ "../views/MasVistos.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/perfil",
    name: "perfil",
    component: () =>
      import(/* webpackChunkName: "perfil" */ "../views/Perfil.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/buscar",
    name: "buscar",
    component: () =>
      import(/* webpackChunkName: "buscar" */ "../views/Buscar.vue"),
    meta: { requiresAuth: true },
  },
  {
    props: true,
    path: "/categoria/:nombre",
    name: "categoria",
    component: () =>
      import(/* webpackChunkName: "categoria" */ "../views/Categoria.vue"),
    meta: { requiresAuth: true },
  },
  {
    props: true,
    path: "/audio/:id",
    name: "audio",
    component: () =>
      import(/* webpackChunkName: "audio" */ "../views/Audio.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const isStandalone = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    // const isStandalone = true;
    if (!isStandalone && to.name !== "instalar") {
        next({ name: "instalar" });
    } else if (to.matched.some((record) => record.meta.requiresAuth) && !store.state.isAuthenticated) {
        next({ name: "login" });
    } else {
        next();
    }
});

export default router
