import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        isAuthenticated: false,
        iniciales: "",
        token: null,
        categorias: [],
        fondo: null,
        audio: null,
        url: null,
        animarDescargas: false,
    },
    getters: {},
    mutations: {
        SET_USER(state, user) {
            state.user = user.user
            state.isAuthenticated = true
        },
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_CATEGORIAS(state, data) {
            state.categorias = data;
        },
        SET_LOGOUT(state) {
            state.user = null;
            state.isAuthenticated = false
            state.audio = null;
            localStorage.removeItem("token");
        },
    },
    actions: {
        async registro ({ commit }, credenciales) {
            try {
                let response = await axios.post("/auth/register", credenciales);
                commit("SET_USER", response.data.user);
                commit("SET_TOKEN", response.data.token);
                localStorage.setItem("token", response.data.token);
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
        async getUser ({ commit }, token) {
            try {
                let response = await axios.get("/api/user", {
                    headers: { Authorization: `Bearer ${token}` }
                });
                commit("SET_USER", response.data);
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
        async getCategorias({ commit, state }) {
            try {
                let response = await axios.get("/api/categorias", {
                    headers: { Authorization: "Bearer " + state.token },
                });
                commit("SET_CATEGORIAS", response.data);
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
        async login ({ commit }, credenciales) {
            try {
                let response = await axios.post("/auth/login", credenciales);
                commit("SET_USER", response.data);
                commit("SET_TOKEN", response.data.token);
                localStorage.setItem("token", response.data.token);
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
        async logout ({ commit, state }) {
            try {
                let response = await axios.post("/auth/logout", {}, {
                    headers: { Authorization: `Bearer ${state.token}` }
                });
                commit("SET_LOGOUT", response.data);
                localStorage.removeItem("token");
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
    },
    modules: {},
});