import { openDB } from 'idb';
export default {
    data:() => ({
        reglas: {
            required: value => !!value || 'Campo requerido.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail no valido.'
          },
        }
    }),
    computed: {
        isRunningStandalone() {
            return (window.matchMedia('(display-mode: standalone)').matches);
        },
        getDeviceType() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        },
    },
    methods: {
        async createDB() {
            // Using https://github.com/jakearchibald/idb
            return await openDB(`${process.env.VUE_APP_SITE_NAME}-descargas`, 1, {
              upgrade(db, oldVersion, newVersion, transaction) {
                // Switch over the oldVersion, *without breaks*, to allow the database to be incrementally upgraded.
                switch (oldVersion) {
                  case 0:
                  // Placeholder to execute when database is created (oldVersion is 0)
                  case 1:
                    // Create a store of objects
                    const store = db.createObjectStore("materiales", {
                      // The `id` property of the object will be the key, and be incremented automatically
                      autoIncrement: true,
                      keyPath: "id",
                    });
                  // Create an index called `name` based on the `type` property of objects in the store
                  // store.createIndex('type', 'type');
                }
              },
            });
        },
        async getData($db) {
            const tx = await $db.transaction('materiales', 'readonly')
            const store = tx.objectStore('materiales');
            // Because in our case the `id` is the key, we would
            // have to know in advance the value of the id to
            // retrieve the record
            return await store.getAll();
        },
        async addData($material) {
            console.log($material);
            const db = await this.createDB()
            const descargas = await this.getData(db)
            let material = Object.assign({}, $material);

            if (descargas.find(descarga => descarga.id == material.id)) {
                return 'existe'
            } else {
                const archivo = await fetch("/" + material.archivo);
                const imagen = await fetch("/"+ material.imagen)
                const archivoBlob = await archivo.blob();
                const imagenBlob = await imagen.blob();
                material.Blob = {
                    archivo: archivoBlob,
                    imagen: imagenBlob,
                }

                const tx = await db.transaction('materiales', 'readwrite');
                const store = tx.objectStore('materiales');
                store.add(material);
                await tx.done;
                return 'agregado'
            }
        },
        async deleteData(id) {
            const db = await this.createDB();
            let trans = db.transaction(["materiales"], "readwrite");
            let store = trans.objectStore('materiales');
            store.delete(id);
        },
        async playAudio($id) {
            try {
                const { data } = await axios.get(`api/archivo?audio_id=${$id}`, {
                  responseType: "arraybuffer",
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                });
                const audioBlob = new Blob([data], { type: "audio/mpeg" });
                let url = URL.createObjectURL(audioBlob);
                return url
                const audio = new Audio(url);
                return audio
                // audio.play();
            } catch (error) {
                console.error("Error al cargar el archivo de audio:", error);
            };
        }
    }
}